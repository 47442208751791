import React from 'react'
import { graphql } from 'gatsby'
import { Layout } from 'components'
import { Hero, MenuItems } from 'slices/menu'

export const query = graphql`
  query aboutMenuQuery {
    prismic {
      menu(uid: "about", lang: "en-us") {
        title
        breadcrumb_title
        hero_image
        items {
          name
          description
          image
          link {
            _linkType
            __typename
            ... on PRISMIC_About {
              _meta {
                uid
              }
            }
          }
        }
        body {
          ... on PRISMIC_MenuBodyContacts {
            primary {
              contact_title
            }
            fields {
              icon
              contact_type
              contact_value
            }
          }
          ... on PRISMIC_MenuBodyBrochure {
            primary {
              brochure_title
              cta_icon
              cta_text
              cta_link {
                ...linkType
              }
            }
          }
        }
      }
    }
  }
`

const About = ({
  data: {
    prismic: { menu },
  },
}) => {
  return (
    <Layout>
      <Hero data={menu} />
      <MenuItems data={menu} />
    </Layout>
  )
}

About.query = query

export default About
